<template>
	<!-- <h2>我是首页</h2> -->
	<el-row :gutter="0">
  <el-col :span="6" :offset="1"><div>
		<div class="demo-image">
				<el-image
				style="width:260px; height: 100%"
				:src="url1"
				:fit="fit"></el-image>
			</div>
	</div></el-col>
  <el-col :span="6" :offset="2"><div>
		<div class="demo-image">
				<el-image
				style="width:280px; height: 100%"
				:src="url3"
				:fit="fit"></el-image>
			</div>
	</div></el-col>
	<el-col :span="6" :offset="2"><div>
		<div class="demo-image">
				<el-image
				style="width:260px; height: 100%"
				:src="url2"
				:fit="fit"></el-image>
			</div>
	</div></el-col>
</el-row>
	
</template>

<script>
	export default {
		name:'Rest',
		data() {
			return {
				fit: 'contain',
				// url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
				url1: '/static/img/rest1.jpeg',
				url2: '/static/img/rest2.jpeg',
				url3: '/static/img/rest3.jpeg',
			}
		},
		computed: {		
		},
		mounted() {
			// console.log(33);
			// console.log(this.$route.name)
		},
	}
</script>