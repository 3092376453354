//人员管理相关的配置
import axios from 'axios'
import { nanoid } from 'nanoid'
export default {
    namespaced: true,
    // actions:{
    // 	addPersonWang(context,value){
    // 		if(value.name.indexOf('王') === 0){
    // 			context.commit('ADD_PERSON',value)
    // 		}else{
    // 			alert('添加的人必须姓王！')
    // 		}
    // 	},
    // 	addPersonServer(context){
    // 		axios.get('https://api.uixsj.cn/hitokoto/get?type=social').then(
    // 			response => {
    // 				context.commit('ADD_PERSON',{id:nanoid(),name:response.data})
    // 			},
    // 			error => {
    // 				alert(error.message)
    // 			}
    // 		)
    // 	}
    // },
    mutations: {
        CHANGELOGIN(state, value) {
            state.islogin = value
        },
        CHANGEINDEX(state, value) {
            state.activeIndex = value
        },
        CHANGEUSERNAME(state, value) {
            state.username = value
        },
    },
    state: {
        islogin: true,
        activeIndex: 'index',
        username: 'eee'
    },
    // getters:{
    // 	firstPersonName(state){
    // 		return state.personList[0].name
    // 	}
    // },
}