<template>
  <el-col :span="14" :offset="5">
    <el-tabs tabPosition="left" style="height: auto" value="down">
      <el-tab-pane disabled>
        <span slot="label"><h2 style="color: #8B7B8B">安装说明</h2></span>
        
      </el-tab-pane>
      <el-tab-pane label="下载地址" name="down">
        <div class="content">
        <h3>最新版本：</h3>
        <p>当前版本为:rabbitPlugin2023-08-28，<a :href="address20230828">点我下载</a></p>
        <p>更新说明：</p>
        <p class="pindent">新增储备一代编辑器快捷预览作品功能</p>
        <p class="pindent">新增储备一代账号切换功能</p>
        <h3>历史版本：</h3>
        <el-collapse v-model="activeNames" @change="handleChange">
  <el-collapse-item title="rabbitPlugin2022-07-25" name="1">
        <h3>当前版本为:rabbitPlugin2022-07-25，<a :href="address20220725">点我下载</a></h3>
        <h3>更新说明：</h3>  
        <p class="pindent">a: 谷歌插件重构，当前版本采用Vue3编写</p>
        <p class="pindent">b: UI整体更新，功能区和配置区分离</p>
        <p class="pindent">c: 新增编辑器带授权预览作品功能</p>
        <p class="pindent">d: 新增玩法配置一键填充功能（未完善，体验功能）</p>
        <p class="pindent">e: 新增主题切换功能</p>
        <p class="pindent">f: 一键创建bug功能升级，UI界面更新</p>
        <p class="pindent">g: 切换账号功能升级，UI界面更新</p>

  </el-collapse-item>
  <el-collapse-item title="..." name="2">
    <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
    <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
  </el-collapse-item>
</el-collapse>
        <!-- <p>rabbitPlugin2022-07-25，<a href="">点我下载</a></p> -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="安装方法">
                <div class="content">

         <h3>安装步骤：</h3>
        <div>
          <el-steps direction="vertical" :active="1">
            <el-step title="步骤 1">
              <span slot="description">
                <p>解压缩下载的谷歌插件文件</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/dist.png"
                  :preview-src-list="srcList0"
                >
                </el-image>
              </span>
            </el-step>
            <el-step title="步骤 2" status="finish">
              <span slot="description">
                <p>打开谷歌浏览器-》更多工具-》扩展程序</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/kuozhangongju.png"
                  :preview-src-list="srcList1"
                >
                </el-image>
              </span>
            </el-step>
            <el-step title="步骤 3" status="finish">
              <span slot="description">
                <p>打开开发者模式，选择加载已解压的扩展程序</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/kaifazhemoshi.png"
                  :preview-src-list="srcList2"
                >
                </el-image>
              </span>
            </el-step>
            <el-step title="步骤 4" status="finish">
              <span slot="description">
                <p>选择解压后的dist文件夹，注意目录选择如图</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/distzhankai.png"
                  :preview-src-list="srcList3"
                >
                </el-image>
              </span>
            </el-step>
            <el-step title="步骤 5" status="finish">
              <span slot="description">
                <p>固定谷歌插件，方便平时使用</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/guding.png"
                  :preview-src-list="srcList4"
                >
                </el-image>
              </span>
            </el-step>
            <el-step title="步骤 6" status="finish">
              <span slot="description">
                <p>打开谷歌插件，能够正常展开，则安装成功</p>
                <p>首次安装成功后需关闭谷歌浏览器重新打开</p>
                <el-image
                  style="width: 200px; height: 200px"
                  src="/static/img/dakai.png"
                  :preview-src-list="srcList5"
                >
                </el-image>
              </span>
            </el-step>
          </el-steps>
        </div>
        </div>
      </el-tab-pane>
      <el-tab-pane disabled>
        <span slot="label"><h2 style="color: #8B7B8B">功能说明</h2></span>
      </el-tab-pane>
      <el-tab-pane label="编辑预览作品">
        <div style="height: auto" class="content">
          <h3>使用说明:</h3>
          <p>1、解决编辑器本身预览设置功能加载缓慢的问题</p>
          <p>2、解决编辑器预览功能不带授权的问题</p>
          <p>
            3、谷歌插件预览功能可直接在编辑中真实预览活动，模拟用户填表单、签到、抽奖等
          </p>
          <p>
            4、谷歌插件预览功能支持模拟客户档案中老用户访问作品、支持模拟新用户访问作品、支持无授权访问作品
          </p>
          <h3>操作步骤:</h3>
          <p>
            1、打开编辑器，如<a target="_blank"
              href="https://editor.rabbitpre.com/?contentType=activityPage"
              >https://editor.rabbitpre.com/?contentType=activityPage</a
            >
          </p>
          <p>2、左上角会注入谷歌插件"保存、预览作品"按钮</p>
          <p>3、看到这两个按钮代表功能启用成功</p>
          <p>4、点击预览作品即可展开预览窗口</p>
          <el-image
            style="width: 500px"
            src="/static/img/editorPreview.png"
            :preview-src-list="srcList6"
          >
          </el-image>
          <h3>配置说明:</h3>
          <p>
            1、保存按钮：功能等同于编辑器本身保存按钮功能，注入该按钮是为了平行预览作品按钮，方便操作
          </p>
          <p>2、预览作品按钮：</p>
          <p class="pindent">
            a: hover时展示作品访问链接，可手机直接扫码访问
          </p>
          <p class="pindent">b: 点击时屏幕右侧展开作品预览窗口</p>
          <p>3、预览窗口：</p>
          <p class="pindent">
            首次打开：首次打开会展示默认用户进行访问作品
          </p>
          <p class="pindent">
            再次打开：再次打开会使用你上一次切换过的用户进行授权，若没有切换过用户，则一直使用默认客户
          </p>
          <p class="pindent">
            关闭：点击右上角X按钮或者点击左侧空白区域可关闭预览窗口
          </p>
          <p class="pindent">
            切换老用户：点击后随机切换客户档案中已有老客户访问作品
          </p>
          <p class="pindent">
            切换新用户：点击后随机生成一个客户档案中不存在的客户访问作品
          </p>
          <p class="pindent">切换访客：点击后进行不授权访问作品</p>
          <p class="pindent">
            刷新: 点击后刷新作品，不改变当前客户，如验证作品加载完成时策略等
          </p>
          <p class="pindent">
            上一页图标:
            若作品高度较短，点击则直接往上翻页，否则滑动至未见区域，继续点击至顶部则翻页
          </p>
          <p class="pindent">
            下一页图标:
            若作品高度较短，点击则直接往下翻页，否则滑动至未见区域，继续点击至底部则翻页
          </p>
          <p class="pindent">页码: 显示当前作品页对应页码</p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="一键创建bug">
        <div style="height: auto" class="content">
          <h3>使用说明:</h3>
          <p>1、兔展的业务大多以作品为维度，bug几乎与作品都有关联</p>
          <p>2、提交bug时需要提供作品信息，一个个查找输入很不方便</p>
          <p>3、谷歌插件实现一键插入作品相关信息功能</p>
          <p>4、谷歌插件实现免登录跳转bug详情功能</p>

          <h3>操作步骤:</h3>
          <p>1、打开谷歌插件进入配置面板(图片区域点击可进入)</p>
          <el-image
            style="width: 200px"
            src="/static/img/shouye.png"
            :preview-src-list="srcList7"
          >
          </el-image>
          <p>2、在账号管理中添加常用的账号，如小程序企业</p>
          <el-image
            style="width: 200px"
            src="/static/img/addAccount.png"
            :preview-src-list="srcList8"
          >
          </el-image>
          <p>3、在查找设置中选择你要提交bug单的作品对应的账号和作品名称含有的字段，如小程序企业，字段long</p>
          <el-image
            style="width: 200px"
            src="/static/img/search.png"
            :preview-src-list="srcList10"
          >
          </el-image>
          <p>
            4、打开创建bug页面，如<a target="_blank"
              href="https://www.tapd.cn/36742836/bugtrace/bugs/add"
              >https://www.tapd.cn/36742836/bugtrace/bugs/add</a
            >
          </p>
          <p>5、测试用例右侧会注入一个作品列表</p>
          <el-image
            style="width: 200px"
            src="/static/img/bugList.png"
            :preview-src-list="srcList9"
          >
          </el-image>
          <p>6、选择一个作品点击，效果如下</p>
          <el-image
            style="width: 200px"
            src="/static/img/bugContent.png"
            :preview-src-list="srcList11"
          >
          </el-image>
          <p>7、继续输入bug内容，点击提交</p>
          <el-image
            style="width: 200px"
            src="/static/img/bugAll.png"
            :preview-src-list="srcList12"
          >
          </el-image>
          <p>8、成功提交后内容如下</p>
          <el-image
            style="width: 200px"
            src="/static/img/tijiao.png"
            :preview-src-list="srcList13"
          >
          </el-image>
          <p>9、点击编辑作品按钮、预览作品按钮可实现免登录跳转</p>
          <h3>配置说明:</h3>
          <p>
            1、账号管理:</p>
          <p class="pindent">支持新增兔展测试环境、正式环境账号</p>
          <p class="pindent">支持删除已创建账号</p>
          <p>
            2、查找设置:</p>
          <p class="pindent">账号：可选择账号管理中已经创建的账号</p>
          <p class="pindent">字段：输入作品包含的字段，建议每个人创建的作品都能带个人标识用于区分</p>
          
        </div>
      </el-tab-pane>
      <el-tab-pane label="一键切换账号">
        <div style="height: auto" class="content">
          <h3>使用说明:</h3>
          <p>1、兔展功能权限和账号息息相关，平时需要频繁切换账号</p>
          <p>2、手动切换账号很不方便，需要退出再登录，耗时费力</p>
          <p>3、谷歌插件实现一键切换账号功能</p>
          <p style="color:red">注：账号切换必须在http或者https页面上进行切换，如在百度、兔展等页面进行切换。不可以在空白页面或浏览器设置等页面进行切换</p>

          <h3>操作步骤:</h3>
          <p>1、打开谷歌插件进入配置面板(图片区域点击可进入)</p>
          <el-image
            style="width: 200px"
            src="/static/img/shouye.png"
            :preview-src-list="srcList7"
          >
          </el-image>
          <p>2、在账号管理中添加常用的账号，如小程序企业</p>
          <el-image
            style="width: 200px"
            src="/static/img/addAccount.png"
            :preview-src-list="srcList8"
          >
          </el-image>
          <p>3、回到首页，点击你想要切换的账号,即可进行切换</p>
          <el-image
            style="width: 200px"
            src="/static/img/clickAccount.png"
            :preview-src-list="srcList14"
          >
          </el-image>
          
        </div>
      </el-tab-pane>
      <el-tab-pane label="填充玩法配置">
        <div style="height: auto" class="content">
          <h3>使用说明:</h3>
          <p>1、每次新建玩法作品配置耗时，如抽奖</p>
          <p>2、谷歌插件实现一键填充玩法配置</p>
          <p>3、当前仅实现抽奖玩法（试行）</p>

          <h3>操作步骤:</h3>
          <p>1、打开编辑器，打开抽奖玩法配置面板,右键选择玩法配置</p>

          <el-image
            style="width: 200px"
            src="/static/img/lottery.png"
            :preview-src-list="srcList15"
          >
          </el-image>
          <p>2、屏幕顶部会弹出进度条，待进度条结束后即完成填充</p>
          <el-image
            style="width: 200px"
            src="/static/img/process.png"
            :preview-src-list="srcList16"
          >
          </el-image>
        </div>
      </el-tab-pane>
      <el-tab-pane label="浏览器访问作品">
        <div style="height: auto" class="content">
          <h3>使用说明:</h3>
          <p>1、测试过程中经常需要多个用户访问作品的场景</p>
          <p>2、测试过程中经常需要新用户访问作品的场景</p>
          <p>3、测试过程中需要其他人帮助助力、组队等</p>
          <p>4、谷歌浏览器插件支持在浏览器中使用授权访问作品</p>
          <p>5、谷歌浏览器插件支持在浏览器中使用固定老用户访问作品</p>
          <p>6、谷歌浏览器插件支持在浏览器中使用随机老用户访问作品</p>
          <p>7、谷歌浏览器插件支持在浏览器中使用新用户访问作品</p>

          <h3>操作步骤:</h3>
          <p>1、打开谷歌插件进入配置面板(图片区域点击可进入)</p>
          <el-image
            style="width: 200px"
            src="/static/img/shouye.png"
            :preview-src-list="srcList7"
          >
          </el-image>
          <p>2、在模拟客户中选择客户模式，如随机老用户</p>
          <el-image
            style="width: 200px"
            src="/static/img/audid.png"
            :preview-src-list="srcList17"
          >
          </el-image>
          <p>3、在浏览器中打开作品，如: <a target="_blank" href="https://v2.rabbitpre.com/m2/FritLUDL15p">https://v2.rabbitpre.com/m2/FritLUDL15p</a></p>
          <el-image
            style="width: 200px"
            src="/static/img/liulanqi.png"
            :preview-src-list="srcList18"
          >
          </el-image>
          <p>4、作品链接带有参数，可正常抽奖</p>

          <h3>配置说明：</h3>
          <p>1、不开启：作品链接仍以原链接在浏览器中访问</p>
          <p>2、新用户：作品链接每次都以新用户形式访问作品</p>
          <p>3、固定老用户：作品链接每次都使用同一个老用户访问作品</p>
          <p>4、随机老用户：作品链接每次都随机使用一个老用户访问作品</p>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-col>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "AbountChrome",
  data() {
    return {
      address20220725:"",
      address20230828:"",
      activeNames: ['1'],
      srcList0: ["/static/img/dist.png"],
      srcList1: ["/static/img/kuozhangongju.png"],
      srcList2: ["/static/img/kaifazhemoshi.png"],
      srcList3: ["/static/img/distzhankai.png"],
      srcList4: ["/static/img/guding.png"],
      srcList5: ["/static/img/dakai.png"],
      srcList6: ["/static/img/editorPreview.png"],
      srcList7: ["/static/img/shouye.png"],
      srcList8: ["/static/img/addAccount.png"],
      srcList9: ["/static/img/bugList.png"],
      srcList10: ["/static/img/search.png"],
      srcList11: ["/static/img/bugContent.png"],
      srcList12: ["/static/img/bugAll.png"],
      srcList13: ["/static/img/tijiao.png"],
      srcList14: ["/static/img/clickAccount.png"],
      srcList15: ["/static/img/lottery.png"],
      srcList16: ["/static/img/process.png"],
      srcList17: ["/static/img/audid.png"],
      srcList18: ["/static/img/liulanqi.png"],
    };
  },
  mounted(){
  let address = window.location.href;
  this.address20220725 = address.substring(0, address.indexOf(this.$route.path) - 2) + "/down?name=/zip/rabbitPlugin20220725.zip" 
  this.address20230828 = address.substring(0, address.indexOf(this.$route.path) - 2) + "/down?name=/zip/rabbitPlugin20230828.zip" 
  },
  components: { Banner },
}
</script>

<style scoped>
.pindent{
  text-indent: 2em
}
.content{
  margin:25px
}
.grid-content {
  border: 1px solid #258aae;
  padding: 0px;
  border-radius: 30px;
}
.card-header {
  padding-top: 0.4rem;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 20px;
  border-bottom: 1px solid #258aae;
}
.long {
  margin: 20px;
}
#hover1 {
  padding: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>