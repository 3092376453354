<template>
	<el-row>
		<el-col :span="6"  class="banner"></el-col>
		<el-col :span="12" :offset="0" class="banner">
			<el-menu
				:default-active="getIndex"
				class="el-menu-demo"
				mode="horizontal"
				@select="handleSelect"
				background-color="#258aae"
				text-color="#fff"
				active-text-color="#ffd04b">
				<el-menu-item class="rong" index="index">首页</el-menu-item>
				<el-submenu index="2">
					<template slot="title">作品</template>
					<el-menu-item index="exportprod" >导出作品</el-menu-item>
					<el-menu-item index="createprod">创建作品</el-menu-item>
					<!-- <el-menu-item index="2-3">选项3</el-menu-item> -->
					<el-submenu index="2-4" disabled>
					<template slot="title">...</template>
					<el-menu-item index="2-4-1">选项1</el-menu-item>
					<el-menu-item index="2-4-2">选项2</el-menu-item>
					<el-menu-item index="2-4-3">选项3</el-menu-item>
					</el-submenu>
				</el-submenu>
					<el-submenu index="3">
					<template slot="title">性能测试</template>
					<el-menu-item index="downloadaudid">audid下载</el-menu-item>
					<el-menu-item index="2-2" disabled>...</el-menu-item>
					
				</el-submenu>
				<el-menu-item index="mock">挡板测试</el-menu-item>
				<el-menu-item index="3" disabled>服务</el-menu-item>
					<el-submenu index="4">
					<template slot="title">帮助文档</template>
					<el-menu-item index="aboutchrome">谷歌插件</el-menu-item>
					<el-menu-item index="2-2" disabled>...</el-menu-item>
				</el-submenu>
				<!-- <el-menu-item class="rong" index="login">登录</el-menu-item>
				<el-menu-item class="rong" index="registeruser">注册</el-menu-item> -->
			</el-menu>
		</el-col>
		<el-col :span="3" :offset="3" class="banner">
			<el-menu
				:default-active="getIndex"
				class="el-menu-demo"
				mode="horizontal"
				@select="handleSelect"
				background-color="#258aae"
				text-color="#fff"
				active-text-color="#ffd04b">
				<el-menu-item v-if="!judgeIslogin" class="rong" index="login">登录</el-menu-item>
				<el-menu-item v-if="!judgeIslogin" class="rong" index="registeruser">注册</el-menu-item>
				<!-- <el-menu-item v-if="judgeIslogin" class="rong" index="registeruser"> -->
				<!-- </el-menu-item> -->
					<el-submenu v-if="judgeIslogin" class="rong" index="registeruser">
					<template slot="title">{{getUsername}}</template>
					<el-menu-item index="2-2" disabled>用户信息</el-menu-item>
					<el-menu-item index="loginout">退出</el-menu-item>
				</el-submenu>

				<!-- <div v-if="false"><p>已登录</p></div> -->
			</el-menu>
		</el-col>
	</el-row>
</template>

<script>
	import axios from 'axios'
	export default {
		name:'Banner',
		data(){
			return {
				// activeIndex2:'mock',
				// activeIndex1:'',
				// islogin: false
			}
		},
		computed:{
			judgeIslogin(){
				return this.$store.state.personAbout.islogin
			},
			getIndex(){
				return this.$store.state.personAbout.activeIndex
			},
			getUsername(){
				return this.$store.state.personAbout.username
			}

		},
		methods: {
			loginOut(){
				axios.get('/logout').then(
					(r) => {
						if (r.data.code === 200) {
							this.$store.commit('personAbout/CHANGELOGIN',false)
							this.$router.push({
								name:"login"
							})
						} else {
							this.$message.error(r.data.data.msg);
						}
					},
					(error) => {
						this.$message.error(r.data);
					}
				);	
			},
			handleSelect(toPath){
				// console.log(this.$route)
				console.log(toPath);
				if(toPath === 'loginout'){
					this.loginOut()
					return
				}
				console.log('判断路由了');
				
				if(!this.$route.name){
					this.$router.push({
						name:toPath
					})
				}
				if(!this.$route.name.includes(toPath) ){
					this.$router.push({
						name:toPath
					})
				}
				
			}
			// back(){
			// 	this.$router.back()
			// 	// console.log(this.$router)
			// },
			// forward(){
			// 	this.$router.forward()
			// },
			// test(){
			// 	this.$router.go(3)
			// }
		},
		created(){
			// this.islogin = true
			console.log('开始拿用户信息'+ this.$store.state.personAbout.islogin);
			axios.get('/getuser').then(
				(r) => {
					if (r.data.islogin) {
						this.$store.commit('personAbout/CHANGELOGIN',true)
						this.$store.commit('personAbout/CHANGEUSERNAME',r.data.username)
					} else {
						this.$store.commit('personAbout/CHANGELOGIN',false)
						if(this.$router.history.current.meta.auth){
							console.log('未登录且需要认证，重定向至登录');
							this.$router.push({
						name:'login'
					})
						}
					}

				},
				(error) => {
					this.message.error(r.data);
				}
			);
			},
	}
</script>

<style scoped>
	.bg-purple-light {
		background: #258aae;
	}

	.banner{
		margin-bottom: 50px;
	}
	 .el-row{
		 background-color: #258aae;
		 height: 60px;
	 }

</style>