// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
//引入组件
import ExportProd from '../pages/ExportProd'
import CreateProd from '../pages/CreateProd'
import Index from '../pages/Index'
import Rest from '../pages/Rest'
import MockTest from '../pages/MockTest'
import NotFound from '../pages/NotFound'
import DownloadAudid from '../pages/DownloadAudid'
import AboutChrome from '../pages/AboutChrome'
import BankWater from '../pages/BankWater'
import RegisterUser from '../pages/RegisterUser'
import Login from '../pages/Login'
import Transit from '../components/Transit'
import store from '@/store'
import { Message } from 'element-ui'

//创建并暴露一个路由器
const router = new VueRouter({
    // mode: 'history',
    routes: [{
            name: 'exportprod',
            path: '/exportprod',
            component: ExportProd,
            meta: {
                title: "导出作品",
                auth: true
            }
        },
        {
            name: 'createprod',
            path: '/createprod',
            component: CreateProd,
            meta: {
                title: "创建作品",
                auth: true
            }
        },
        {
            name: 'index',
            path: '/',
            component: Index,
            meta: {
                title: "首页",
                auth: false
            }
        },
        {
            name: 'rest',
            path: '/rest',
            component: Rest,
            meta: {
                title: "休息",
                auth: false
            }
        },
        {
            name: 'mock',
            path: '/mock',
            component: MockTest,
            meta: {
                title: "挡板测试",
                auth: true
            }

        },
        {
            name: 'bankwater',
            path: '/bankwater',
            component: BankWater,
            meta: {
                title: "银行流水对比",
                auth: false
            }

        },
        {
            name: 'downloadaudid',
            path: '/downloadaudid',
            component: DownloadAudid,
            meta: {
                title: "audid下载",
                auth: true
            }
        },
        {
            name: 'registeruser',
            path: '/registeruser',
            component: RegisterUser,
            meta: {
                title: "注册",
                auth: false
            }
        },
        {
            name: 'aboutchrome',
            path: '/aboutchrome',
            component: AboutChrome,
            meta: {
                title: "谷歌插件说明",
                auth: false
            }
        },
        {
            name: 'login',
            path: '/login',
            component: Login,
            meta: {
                title: "登录",
                auth: false
            }
        },
        {
            name: 'transit',
            path: '/transit',
            component: Transit,
            meta: {
                title: "登录",
                auth: false
            }
        },
        {
            name: "404",
            path: '*',
            component: NotFound,
            meta: {
                title: "404",
                auth: true
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.auth)) {
        if (!store.state.personAbout.islogin) {
            console.log('未登录，重定向至登录界面');
            // a = Message()
            Message({
                message: '请登录！',
                type: "error",
                duration: 500,
            })
            next({ 'path': '/login' })
        }
        console.log('已登录，放行');

        next()
    }
    next()
})

router.afterEach((to) => {
    document.title = to.meta.title
    console.log('路由后置守卫');
    console.log(to.name);
    store.commit('personAbout/CHANGEINDEX', to.name)
})


// this.$store.commit('personAbout/CHANGELOGIN', true)

export default router