<template>
  <el-col :span="8" :offset="7" v-loading="form.loading">
    <div class="grid-content bg-purple-dark">
      <h1 class="card-header">audid下载</h1>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        class="long"
        label-width="80px"
      >
        <el-form-item label="选择账号" prop="value">
          <el-select v-model="form.value" placeholder="请选择">
            <el-option-group label="测试环境">
              <el-option
                v-for="item in testoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-option-group>
            <el-option-group label="正式环境">
              <el-option
                v-for="item in prodoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-link :href="address" id="autoClickA" style="display:None"></el-link>
        <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        <el-button>取消</el-button>
      </el-form>
    </div>
  </el-col>
</template>

<script>
import Banner from "@/components/Banner.vue";
import axios from "axios";
export default {
  name: "DownloadAudid",
  data() {
    return {
      address: '',
      form: {
        value: "",
        loading: false,
      },
      rules: {
        value: [{ required: true, message: "请选择账号", trigger: "blur" }],
      },
      options: [
        {
          id: 2,
          name: "龙氏集团",
          account: "18814183812",
          password: "123456",
          env: 'test',
        },
         {
          id: 1,
          name: "小程序企业",
          account: "18544444444",
          password: "123456",
          env: 'test',
        },
        {
          id: 3,
          name: "longlongago",
          account: "18814183812",
          password: "123456",
          env: 'prod',
        },
      ],
    };
  },
  created(){
    this.form.value = this.options[0].id
  },
  computed: {
    testoptions() {
      return this.options.filter((option) => option.env === 'test');
    },
    prodoptions() {
      return this.options.filter((option) => option.env === 'prod');
    },
  },
  methods: {
    onSubmit(a) {
      this.$refs[a].validate((valid) => {
        if (valid) {
          let data = this.options.filter(
            (option) => option.id === this.form.value
          )[0];
          console.log(this.form.value);
          console.log(data);
          this.form.loading = true;
          axios({
            method: 'post',
            url: "/downloadaudid", 
            data: data,
            // responseType: 'blob'
        }).then(
            (response) => {
              this.form.loading = false;
              if (response.data.code === 200) {
                let address = window.location.href;
                this.address = address.substring(0, address.indexOf(this.$route.path) - 2) + "/down?name=" + response.data.path
                this.$nextTick(function(){
                  document.getElementById('autoClickA').click()
                })
                this.$message({
                  message: response.data.msg,
                  type: "success",
                });
              } else {
                this.$message.error(response.data.msg);
              }
              return;
            },
            (error) => {
              console.log("失败了");
              this.form.loading = false;
              this.$message.error(response.data);
              return;
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelect(a) {
      console.log("handleSelect");
      console.log(a);
    },
  },
  components: { Banner },
};
</script>

<style scoped>
.grid-content {
  border: 1px solid #258aae;
  padding: 0px;
  border-radius: 30px;
}
.card-header {
  padding-top: 0.4rem;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 20px;
  border-bottom: 1px solid #258aae;
}
.long {
  margin: 20px;
}
#hover1 {
  padding: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>