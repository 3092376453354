//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'

import store from './store'


//完整引入
//引入ElementUI组件库
// import ElementUI from 'element-ui';
//引入ElementUI全部样式
// import 'element-ui/lib/theme-chalk/index.css';

import {
    Dialog,
    Menu,
    Drawer,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    Upload,
    Collapse,
    CollapseItem,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    Step,
    Steps,
    OptionGroup,
    Button,
    ButtonGroup,
    Form,
    Tabs,
    FormItem,
    Icon,
    Row,
    Col,
    TabPane,
    Card,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Link,
    Image,
    Divider,
    Loading,
    MessageBox,
    Message,
    Popover
} from 'element-ui';
Vue.use(Dialog);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(Drawer);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(Popover);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Form);
Vue.use(Tabs);
Vue.use(FormItem);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(TabPane);
Vue.use(Card);
Vue.use(Container);
Vue.use(Header);
Vue.use(Upload);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Loading.directive);
//按需引入
//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
//引入复制
import VueClipboard from 'vue-clipboard2'


Vue.prototype.$loading = Loading.service;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;


//关闭Vue的生产提示
Vue.config.productionTip = false

//应用ElementUI
Vue.use(VueRouter)
Vue.use(VueClipboard)

//创建vm
new Vue({
    el: '#app',
    store,
    render: h => h(App),
    router: router
})

// 111