<template>
  <el-col :span="18" :offset="3" class="banner">
    <!-- <el-radio v-if="false" label="rtl"></el-radio> -->
    <el-drawer
      title="日志显示"
      :visible.sync="drawer"
      direction="rtl"
      size="100%"
      :before-close="handleClose"
    >
      <el-menu :default-openeds="['1']">
        <el-submenu index="1">
          <!-- <template slot="title" ><i class="el-icon-menu"></i>日志显示</template> -->
          <el-menu-item-group>
            <transition-group name="todo" appear>
              <el-menu-item
                style="padding-right: 0px; padding-left: 0px"
                v-for="(p) of loglists"
                :key="p"
              >
                <span style="margin-left: 0px" class="lispan">{{
                  p
                }}</span>
              </el-menu-item>
            </transition-group>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-drawer>
    <el-container class="grid-content" style="height: 550px">
      <el-main>
        <div class=" bg-purple-dark">
          <el-header>
            <h1>挡板测试</h1>
          </el-header>
          <el-main>
            <el-form
              ref="form"
              :rules="rules"
              :model="form"
              class="long"
              label-width="80px"
            >
              <el-form-item label="接口路径" prop="path">
                <el-input
                  maxlength="20"
                  show-word-limit
                  prefix-icon="el-icon-star-on"
                  v-model.trim="form.path"
                  autocomplete="off"
                  placeholder="如：award"
                ></el-input>
              </el-form-item>
              <el-form-item label="接口名称">
                <el-input
                  maxlength="6"
                  show-word-limit
                  prefix-icon="el-icon-star-on"
                  v-model.trim="form.name"
                  autocomplete="off"
                  placeholder="若不输入，则名称等于路径"
                ></el-input>
              </el-form-item>

              <el-form-item label="返回格式">
                <el-radio v-model="form.genre" label="json">json</el-radio>
                <el-radio v-model="form.genre" label="xml">xml</el-radio>
              </el-form-item>

              <el-form-item class="response" label="响应下行" prop="content">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model.trim="form.content"
                  show-word-limit
                  rows="8"
                >
                </el-input>
              </el-form-item>
              <el-button type="primary" @click="subit('form')">确定</el-button>
              <el-button>取消</el-button>
            </el-form>
          </el-main>
        </div>
      </el-main>
      <el-aside
        class="grid-content todo-main"
        width="300px"
        style="margin: 20px"
      >
        <el-menu :default-openeds="['1', '3']">
          <el-submenu index="1">
            <template slot="title"
              ><i class="el-icon-menu"></i>Json格式</template
            >
            <el-menu-item-group>
              <transition-group name="todo" appear>
                <el-menu-item
                  style="padding-right: 0px; padding-left: 0px"
                  v-for="p of getJsonLists"
                  :key="p.id"
                  :index="p.id"
                  @click="jumpToInterface(p.id)"
                >
                  <span style="margin-left: 0px" class="lispan">{{
                    p.name
                  }}</span>
                  <el-button
                    class="deletebutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="deletelist(p.id)"
                    >删除</el-button
                  >
                  <el-button
                    class="copybutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="copy(p.id)"
                    >复制</el-button
                  >
                  <el-button
                    class="catbutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="openDraw(p.id)"
                    >日志</el-button
                  >
                </el-menu-item>
              </transition-group>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title"
              ><i class="el-icon-document"></i>Xml格式</template
            >
            <el-menu-item-group>
              <transition-group name="todo" appear>
                <el-menu-item
                  style="padding-right: 0px; padding-left: 0px"
                  v-for="p of getXmlLists"
                  :key="p.id"
                  :index="p.id"
                  @click="jumpToInterface(p.id)"
                >
                  <span style="margin-left: 0px" class="lispan">{{
                    p.name
                  }}</span>
                  <el-button
                    class="deletebutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="deletelist(p.id)"
                    >删除</el-button
                  >
                  <el-button
                    class="copybutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="copy(p.id)"
                    >复制</el-button
                  >
                  <el-button
                    class="catbutton"
                    type="primary"
                    round
                    size="mini"
                    @click.stop="openDraw(p.id)"
                    >日志</el-button
                  >
                </el-menu-item>
              </transition-group>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
    </el-container>
  </el-col>
</template>

<script>
import axios from "axios";
import { Header } from "element-ui";
import { MessageBox } from 'element-ui'
export default {
  name: "MockTest",
  data() {
    return {
      isleave:false,
      closeTime:5,
      drawer: false,
      form: {
        name: "",
        path:"",
        content: "",
        genre: "json",
      },
      rules: {
        path: [{ required: true, message: "请输入接口路径", trigger: "blur" }],
        content: [
          { required: true, message: "请输入返回内容", trigger: "blur" },
        ],
      },
      lists: JSON.parse(localStorage.getItem("mocklists")) || [],
      loglists:[]
    };
  },
  methods: {
    
    getLastPath(val) {
      let address = window.location.href;
      let lastpath =
        address.substring(0, address.indexOf(this.$route.path) - 2) +
        "/interface/" +
        val;
      return lastpath;
    },
    
    jumpToInterface(val) {
      let lastpath = this.getLastPath(val);
      console.log(lastpath);
      window.open(lastpath);
    },
    copy(val) {
      let lastpath = this.getLastPath(val);
      console.log(lastpath);
      this.$copyText(lastpath)
        .then(() => {
          this.$message({
            message: "复制成功",
            type: "success",
          });
        })
        .catch(() => {
          console.log("复制失败");
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          console.log(done);
          done();
          clearInterval(this.timerGetLog);
          clearInterval(this.timerJumpIsLeave);
          this.timerGetLog = null;
          this.timerJumpIsLeave = null;
          this.loglists = []
        })
        .catch((_) => {});
    },
    deletelist(id) {
      this.lists = this.lists.filter((list) => list.id !== id);
    },
    searchLogs(id){
      console.log('getlogs');
      
      axios.get('/getlogs',{params:{'id':id}}).then(
        (r) => {
          if (r.data.code === 200) {
            this.loglists = r.data.data
          } else {
            this.$message.error(r.data.data.msg);
          }
        },
        (error) => {
          console.log(error)
        }
      );
    
    },
    closea(){
      if(this.isleave){
        MessageBox.close(false)
        this.drawer = false
        clearInterval(this.timerGetLog);
        clearInterval(this.timerJumpIsLeave);
        this.timerGetLog = null;
        this.timerJumpIsLeave = null;
        this.loglists = []
      }
      
    },
    closeDrawer() {
        console.log('自动关闭');
        this.isleave = true
        setTimeout(this.closea, 5000)
        MessageBox.confirm('我来问问你还在吗？不在的话5s后关闭弹窗了', {
          confirmButtonText: '在', 
          cancelButtonText: '不在',
          type: 'warning'
        }).then(() => {
          this.isleave = false
          this.$message({
            type: 'info',
            message: '那算了'
          });
        }).catch(() => {
          this.drawer = false
          clearInterval(this.timerGetLog);
          clearInterval(this.timerJumpIsLeave);
          this.timerGetLog = null;
          this.timerJumpIsLeave = null;
          this.loglists = []
        });
    },
    openDraw(id) {
      this.drawer = true;
      // this.loglists = []
      console.log('请求了getlog');
      this.timerGetLog = window.setInterval(() => {
        setTimeout(this.searchLogs(id), 0);
      }, 1000);
      this.timerJumpIsLeave = window.setInterval(() => {
        setTimeout(this.closeDrawer, 0)
      }, 600000);
      // setTimeout(this.closeDrawer, 3000)

    },
    subit(form) {
      console.log(form);
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log("校验通过");
          let name = this.form.name
          if(!name){
            name = this.form.path
          }
          let data = {
            name: name,
            path: this.form.path,
            content: this.form.content,
            genre: this.form.genre,
          };
          axios.post("/setinterface", data).then(
            (response) => {
              this.form.loading = false;
              if (response.data.code === 200) {
                this.$message({
                  message: "创建成功",
                  type: "success",
                });
                this.lists.unshift({
                  name: response.data.data.name,
                  id: response.data.data.responseid,
                  genre: response.data.data.responseType,
                });
              } else {
                this.$message.error(response.data.data.msg);
              }
              return true;
            },
            (error) => {
              console.log("失败了");
              this.form.loading = false;
              this.$message.error(response.data);
              return false;
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  destroyed(){
    clearInterval(this.timerGetLog);
    clearInterval(this.timerJumpIsLeave);
    this.timerGetLog = null;
    this.timerJumpIsLeave = null;
  },
  computed: {
    getJsonLists() {
      return this.lists.filter((a) => {
        return a.genre === "json";
      });
    },
    getXmlLists() {
      return this.lists.filter((a) => {
        return a.genre === "xml";
      });
    },
  },
  watch: {
    lists: {
      deep: true,
      handler(value) {
        localStorage.setItem("mocklists", JSON.stringify(value));
      },
    },
    // form:{
    //   deep:true,
    //   handler(newValue, oldVale){
    //   console.log(newValue);
    //   this.form.path = newValue.path.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g,"")
    //   }
    // },
    
  },
};
</script>

<style>
.el-header,
.el-footer {
  color: #333;
  text-align: center;
  line-height: 30px;
  /* border-bottom: 1px solid #258aae; */
}


.el-aside {
  /* background-color: #D3DCE6; */
  /* color: #333; */
  text-align: center;
  line-height: 200px;
}

.el-main {
  /* background-color: #E9EEF3; */
  /* color: #333; */
  text-align: left;
  line-height: 160px;
}
.el-main .el-radio{
  text-align: left;
}

body > .el-container {
  margin-bottom: 40px;
}


.grid-content {
  border: 1px solid #258aae;
  padding: 0px;
  border-radius: 30px;
}
.card-header {
  padding-top: 0.4rem;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 20px;
  border-bottom: 1px solid #258aae;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
/* .card-header{ 
		padding-top: 0.4rem;
		margin-top: 0px;
		margin-left: 0px;
		padding-left: 20px;
		border-bottom: 1px solid #258aae;

	} */
.response {
  margin-bottom: -45px;
}
button {
  margin-left: 10px;
  /* float: right; */
}

.copybutton {
  float: right;
  margin-right: 2px;
  margin-top: 10px;
}
.deletebutton {
  float: right;
  margin-right: 4px;
  margin-top: 10px;
}
.catbutton {
  float: right;
  margin-right: -7px;
  margin-top: 10px;
}
.lispan {
  float: left;
  margin-left: 10px;
  padding-left: 10px;
}
/* .listyle{
  background-color: green; 
  padding-left: 10px;
} */
.todo-main {
  margin-left: 0px;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 0px;
}

.todo-empty {
  height: 40px;
  line-height: 40px;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding-left: 5px;
  margin-top: 10px;
}


.todo-enter-active {
  animation: come 0.5s linear;
}

.todo-leave-active {
  animation: leave 0.5s linear;
}

@keyframes come {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes leave {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
