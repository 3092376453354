<template>
	<!-- <div><h1>跳转中。。。{{aaa}}</h1></div> -->
	<iframe id="tuzhan" src="https://www.rabbitpre.com/" frameborder="0" style="width:100%;height: 1000px;"></iframe>
</template>

<script>
	import axios from 'axios'
	export default {
		name:'Transit',
		data(){
			return {
			}
		},
		methods: {
			aaa(){
				let url = document.location.href
			console.log('99999');
			
			console.log(url);
			console.log('9999');
			return url
			},
			login(info) {
      let lastLoginEnv = (info.env === "test") ? "test":""
      let loginUrl = `https://${lastLoginEnv}passport.rabbitpre.com/api/uaa/login/`;
      let data = {
        account: info.account,
        password: info.password,
        fromType: "pc",
        loginType: 1,
      };
      axios({
					method:"post",
				//  url:"/long/api/uaa/login/",
				 url:loginUrl,
				 headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Method":"POST,GET,OPTIONS"
				 },
				 data:data}).then(
        (r) => {
          if (r.data.code === "200") {
            console.log("登录成功");
          } else {
            console.log(r);
            alert("登录失败");
          }
        },
        (error) => {
          console.log(error);
          alert("登录异常");
        }
      );
    }
			
		},
		created(){
			let url = document.location.href
			console.log('99999');
			
			console.log(url);
			console.log('9999');
			let a = url.split("name=")
			console.log(a);
			console.log(a[1]);
			let info = {account:"18544444444", password:"123456",env:"test"}
			// this.login(info)
			console.log('9999');
			setTimeout(function(){
				console.log('时间到了');
				// let loginBtn = document.getElementById("g-j-signin-btn")
				let loginBtn = document.getElementById("tuzhan").contentWindow.document.body.querySelector("#g-j-signin-btn")
				console.log(loginBtn);
				
				loginBtn.click()
			},3000)
			

			
			
			},
	}
</script>

<style scoped>


</style>