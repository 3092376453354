<template>
	<el-col :span="12" :offset="6">
		<div class="demo-image">
				<el-image
				@click="toIndex"
				style="width:100%; height: 500px"
				:src="url"
				:fit="fit"></el-image>
			</div>
	</el-col>
</template>

<script>
	export default {
		name:'NotFound',
		data() {
			return {
				fit: 'contain',
				// url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
				url: '/static/404.png'
			}
		},
		methods:{
			toIndex(){
				this.$router.push({
					name:'index'
				})
			}
		}
	}
</script>