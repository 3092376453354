<template>
	<!-- <h2>我是首页</h2> -->
	<div>
	<el-col :span="12" :offset="6">
		<div class="demo-image">
				<el-image
				style="width:100%; height: 500px"
				:src="url"
				:fit="fit"></el-image>
			</div>
	</el-col>
	<el-col :span="4" :offset="10">
		备案:
		<a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2020020811号-1</a>
	</el-col>
</div>
</template>

<script>
	export default {
		name:'Index',
		data() {
			return {
				fit: 'contain',
				// url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
				url: '/static/index.jpeg'
			}
		},
		computed: {		
		},
		mounted() {
			// console.log(this.$route)
		},
	}
</script>