<template>
  <el-col :span="8" :offset="7" v-loading="loading">
    <div class="grid-content bg-purple-dark">
      <h1 class="card-header">银行流水对比</h1>
      <el-container>
        <el-side :style="setAsideWidth">
                <div style="margin:30px">
<el-upload
  class="upload-demo"
  action="/bankwater"
  :on-preview="handlePreview1"
  :on-remove="handleRemove1"
  :before-upload="handleBefore1"
  :data = "uploadData"
  :limit="1">
  <el-button size="small" type="primary">点击上传</el-button>
  <div slot="tip" class="el-upload__tip">上传财务流水</div>
</el-upload>
<el-upload
  class="upload-demo"
  action="/bankwater"
  :on-preview="handlePreview2"
  :on-remove="handleRemove2"
  :before-upload="handleBefore2"
  :data = "uploadData"
  :limit="1">
  <el-button size="small" type="primary">点击上传</el-button>
  <div slot="tip" class="el-upload__tip">上传银行流水</div>
</el-upload>
</div>
<div style="margin-left:30px;margin-bottom: 10px;">
        <el-link :href="address" id="autoClickA" style="display:None"></el-link>

  <el-button type="success" @click="handelUpload">确定</el-button>
  </div>
        </el-side>
        <el-main>
          <img src="/static/img/huanxi.jpeg" alt="" width="140px">
        </el-main>
      </el-container>

    </div>
  </el-col>
</template>

<script>
import Banner from "@/components/Banner.vue";
import axios from "axios";
import { nanoid } from 'nanoid'
export default {
  name: "BankWater",
  data() {
    return {
      address:'',
      loading:false,
      uploadData:{
        fileName:'',
        fileName1:'',
        fileName2:'',
      },
    };
  },
  created(){
  },
  computed: {
    setAsideWidth(){
      if(this.uploadData.fileName1 || this.uploadData.fileName2){
        return "width:50%"
      }else{
        return "width:30%"
      }
      return
    }
  },
  methods: {
      handleRemove1(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview1(file) {
        console.log(file);
      },
      handleBefore1(){
        let fileId = nanoid()
        this.uploadData.fileName = fileId
        this.uploadData.fileName1 = fileId
      },
      handleRemove2(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview2(file) {
        console.log(file);
      },
      handleBefore2(){
        let fileId = nanoid()
        this.uploadData.fileName = fileId
        this.uploadData.fileName2 = fileId
      },
      handelUpload(){
        if(!this.uploadData.fileName1 || !this.uploadData.fileName2){
          this.$message({
                  message: "请上传文件",
                  type: "error",
                });
          return
        }
        this.loading = true
        axios({
            method: 'post',
            url: "/handlebankwater", 
            data: this.uploadData,
        }).then(
            (response) => {
              if (response.data.code === 200) {
                let address = window.location.href;
                this.address = address.substring(0, address.indexOf(this.$route.path) - 2) + "/down?name=" + response.data.path
                this.$nextTick(function(){
                  document.getElementById('autoClickA').click()
                })
                this.$message({
                  message: response.data.msg,
                  type: "success",
                });
              } else {
                this.$message.error(response.data.msg);
              }
              this.loading = false
              return;
            },
            (error) => {
              console.log("失败了");
              this.loading = false;
              this.$message.error(response.data);
              return;
            }
          );
      }

  },
  components: { Banner },
};
</script>

<style scoped>
.grid-content {
  border: 1px solid #258aae;
  padding: 0px;
  border-radius: 30px;
}
.card-header {
  padding-top: 0.4rem;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 20px;
  border-bottom: 1px solid #258aae;
}
.long {
  margin: 20px;
}
#hover1 {
  padding: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>