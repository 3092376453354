<template>
  <div>
    <Banner />
    <router-view></router-view>
  </div>
</template>

<script>
  import Banner from './components/Banner.vue';
	export default {
    name: "App", 
    // data(){
    //   return {
    //     isshow:true
    //   }
    // },
    components:{ Banner},
    // mounted(){
    //   if(this.$route.name=="rest"){
    //     this.isshow = false
    //   }
    // }
}
</script>

<style>
  /* .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  } */
  
</style>
