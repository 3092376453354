<template>
  <el-col :span="12" :offset="6" v-loading="form.loading">
    <div class="grid-content bg-purple-dark">
      <h1 class="card-header">作品创建功能</h1>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        class="long"
        label-width="80px"
      >
        <el-form-item label="选择环境">
          <!-- <el-switch v-model="form.delivery"></el-switch> -->
          <el-switch
            v-model="form.env"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="正式环境"
            inactive-text="测试环境"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="账号名称" prop="username">
          <el-input
            maxlength="15"
            show-word-limit
            prefix-icon="el-icon-star-on"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号密码" prop="password">
          <el-input
            maxlength="15"
            show-password
            prefix-icon="el-icon-star-on"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        <el-button>取消</el-button>
      </el-form>
    </div>
  </el-col>
</template>

<script>
import Banner from "@/components/Banner.vue";
import axios from "axios";
export default {
  name: "CreateProd",
  data() {
    return {
      form: {
        env: false,
        username: "",
        password: "",
        loading: false,
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  computed: {
  },
  methods: {
    onSubmit(a) {
      this.$refs[a].validate((valid) => {
        if (valid) {
          let env = "test";
          if (this.form.env) {
            env = "prod";
          }
          let data = {
            env: env,
            username: this.form.username,
            password: this.form.password,
          };
          console.log(data);
          this.form.loading = true;
          axios.post("/handlecreateprod", data).then(
            (response) => {
              this.form.loading = false;
              if (response.data.code === 200) {
                this.$message({
                  message: response.data.msg,
                  type: "success",
                });
              } else {
                this.$message.error(response.data.msg);
                // this.$store.commit('personAbout/CHANGELOGIN',false)
                //   this.$router.push({
                //   name:'login'
                // })
              }
              return;
            },
            (error) => {
              console.log("失败了");
              this.form.loading = false;
              this.$message.error(response.data);
              return;
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: { Banner },
};
</script>

<style scoped>
.grid-content {
  border: 1px solid #258aae;
  padding: 0px;
  border-radius: 30px;
}
.card-header {
  padding-top: 0.4rem;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 20px;
  border-bottom: 1px solid #258aae;
}
.long {
  margin: 20px;
}
#hover1 {
  padding: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>